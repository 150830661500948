import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import YouTube from 'react-youtube';
import { FiExternalLink } from "react-icons/fi";

const Media = ({data}) => (
  <Layout>
    <SEO title="Media" />
    
    <div className="lg:px-4 mt-16">
      <div className="lg:flex lg:-mx-4">
        <div className="w-full lg:w-1/4 lg:pr-4 max-w-xl -mt-12">
          <div className="lg:sticky lg:top-0 pt-12">
            <h2 className="text-sand-400 text-4xl lg:text-5xl leading-none uppercase mb-2 font-semibold">Media</h2> 
            <p className="mb-4">
              Foto & videomateriaal van Classicurls.
            </p> 
            <p>
              Bekijk ook onze <a href="https://nl-nl.facebook.com/classicurls/" target="_blank" rel="noopener noreferrer" className="underline">Facebook <FiExternalLink className="inline-block"/></a> voor meer beeldmateriaal!
            </p>
          </div>
        </div>

        <div className="lg:flex-1 lg:pl-4 mt-8 lg:text-right">
          <div className="videoWrapper mb-4 lg:mb-8">
            <YouTube videoId="13px4NxdPfk" className="absolute inset-0 w-full h-full"/>
          </div>

          <Img fluid={data.image1.childImageSharp.fluid} className="max-h-screen mb-4 lg:mb-8" />

          <Img fluid={data.image2.childImageSharp.fluid} className="max-h-screen mb-4 lg:mb-8" />

          <Img fluid={data.image3.childImageSharp.fluid} className="max-h-screen" />
          
        </div>

      </div>
    </div>

  </Layout>
)

export const HeaderImage = graphql`
    fragment mediaImage on File {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  
    query {
      image1: file(relativePath: { eq: "media/Classicurls-01.jpg" }) {
        ...mediaImage
      }
  
      image2: file(relativePath: { eq: "media/Classicurls.jpg" }) {
        ...mediaImage
      }

      image3: file(relativePath: { eq: "media/328318119968768_o.jpg" }) {
        ...mediaImage
      }
    }
  `

export default Media
